/* import { json } from 'node:stream/consumers' */
import { UseSeoMetaInput } from '@unhead/vue'
import type { Material } from './useMenuModel'

export const useOgMeta = (
  host: string,
  name?: string,
  desc?: string,
  image?: string
): UseSeoMetaInput => ({
  ogLocale: 'ar',
  ogType: 'website',
  ogUrl: host,
  ogTitle: name,
  ogImage: image,
  ogImageSecureUrl: image,
  ogImageAlt: name,
  ogDescription: desc,
  twitterCard: 'summary_large_image',
  twitterTitle: name,
  twitterImage: image,
  twitterImageAlt: name,
  twitterDescription: desc
})

export const useSeoCanonical = (href: string) => ({
  rel: 'canonical',
  href
})

export const useSeoBreadcrumbItem = (
  position?: number,
  name?: string,
  item?: string
) => ({
  '@type': 'ListItem',
  position,
  name,
  item
})

export const useSeoBreadcrumb = (itemListElement?: any) => ({
  type: 'application/ld+json',
  children: {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement
  }
})

export const useSeoLogo = (url?: string, logo?: string) => ({
  type: 'application/ld+json',
  children: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url,
    logo
  }
})

export const useSeoProduct = (material: Material) => {
  const { url } = useDomainHost()

  const script = {
    type: 'application/ld+json',
    children: {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      id: `${material.id}`,
      name: material.name,
      description: material.summary,
      price: `${material.finalPrice.toFixed(2)} ${
        material.store?.display_currency?.iso ?? ''
      }`,
      availability: 'https://schema.org/InStock',
      // Extract Small Thumb As List Of Images
      // thumb: We added it in useMenuModel
      image: material.images?.map((el) => el.thumb),
      url: `${url}/materials/${material.id}`,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: material.average_rate || 5,
        reviewCount: material.rate_count || 1
      }
    }
  }

  if (material.mark) {
    // @ts-ignore
    script.children.brand = {
      '@type': 'Brand',
      name: material.mark.name
    }
  } else if (material.store) {
    // @ts-ignore
    script.children.brand = {
      '@type': 'Brand',
      name: material.store.name
    }
  }

  if (material.offers?.length) {
    // @ts-ignore
    script.children.offers = {
      '@type': 'Offer',
      url: `${url}/materials/${material.id}`,
      priceCurrency: material.store?.display_currency?.iso ?? '',
      price: material.offers[0].new_price.toFixed(2),
      priceValidUntil: material.offers[0].end_date,
      itemCondition: 'https://schema.org/UsedCondition',
      availability: 'https://schema.org/InStock'
    }
  }

  return script
}
